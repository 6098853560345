import { useSelector, useDispatch } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import { setMessage } from "../actions/systemAction";
import { TempBtn } from "../Component/Input/TempBtn"
import { getString } from "../helper/util";
import authProvider from "../authProvider";


export const DialogWrapper = ({ dialogInfo, sx }) => {
    const dispatch = useDispatch();
    const lang = useSelector(state => state.lang.ui.lang);
    const theme = useSelector(state => state.theme.ui.theme);
    const message = useSelector(state => state.system.ui.message);
    const messageType = useSelector(state => state.system.ui.messageType);

    function handleDialogClose() {
        dispatch(setMessage(""));

        if(messageType && messageType === 401) {
            authProvider.logout();
        }
    }

    return (message || dialogInfo) ?
        <Dialog
            open={message ? true : dialogInfo.open ? dialogInfo.open : false}
            onClose={dialogInfo.onClose ? dialogInfo.onClose : ()=>handleDialogClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={dialogInfo.maxWidth ? dialogInfo.maxWidth : "sm"}
            sx={{zIndex: theme => theme.zIndex.drawer - 1, ...sx}}
        >
            {(dialogInfo.dialogTitle || dialogInfo.dialogSubTitle) &&
                <DialogTitle id="alert-dialog-title">
                    {`${getString(lang, dialogInfo.dialogTitle.resource ? dialogInfo.dialogTitle.resource : "", dialogInfo.dialogTitle.key ? dialogInfo.dialogTitle.key : "", theme)}${dialogInfo.dialogSubTitle ? dialogInfo.dialogSubTitle : ""}`}
                </DialogTitle>
            }

            {(!dialogInfo.dialogTitle && !dialogInfo.dialogSubTitle) &&
                <DialogTitle id="alert-dialog-title">
                    {`${getString(lang, "", "dialogTitle", theme)}`}
                </DialogTitle>
            }


            <DialogContent>
                {message ? message : dialogInfo.dialogContent}
                {dialogInfo.dialogContentText &&
                    <DialogContentText component="span" id="alert-dialog-description">
                        {getString(lang, dialogInfo.dialogContentText.resource ? dialogInfo.dialogContentText.resource : "", dialogInfo.dialogContentText.key ? dialogInfo.dialogContentText.key : "", theme)}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                {dialogInfo.dialogActions ?
                    dialogInfo.dialogActions.map((item, index) => (
                        <TempBtn key={"btn_" + index} btnSetting={item} />
                    ))
                    : <TempBtn btnSetting={{ label: { key: "close" }, onClick: () => handleDialogClose(), icon: <CloseIcon /> }} />
                }
            </DialogActions>
        </Dialog>
        : <></>
}